<template>
  <div>
    <div class="_BoxHeader">
      <span>岗位职能表权限设置</span>
    </div>
    <div class="PageContain">
      <div class="set-box">
        <p>
          当前状态：<span>{{ isOff2 ? '开' : '关' }}</span>
        </p>
        <div class="set-btn">
          设置状态：<el-button
            style="margin-left: 15px"
            :type="[isOff ? 'success' : '']"
            @click="handleClick(true)"
            >打开权限</el-button
          >
          <el-button
            style="margin-left: 25px"
            :type="[isOff ? '' : 'success']"
            @click="handleClick(false)"
            >关闭权限</el-button
          >
        </div>
        <el-button
          style="position: absolute; bottom: 30px; right: 40px"
          type="primary"
          @click="reqData"
          :disabled="!showOff"
          >确定</el-button
        >
        <el-button
          style="position: absolute; bottom: 30px; right: 105px"
          @click="cancle"
          :disabled="!showOff"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { put } from '../../api/http'
export default {
  data() {
    return {
      isOff: true,
      isOff2: true,
      showOff: false,
    }
  },
  mounted() {
    this.getStatus()
  },
  methods: {
    getStatus() {
      this.isOff = this.$store.state.postAudFunStatus
      this.isOff2 = this.$store.state.postAudFunStatus
    },
    handleClick(isOff) {
      this.isOff = isOff
      this.showOff = true
    },
    cancle() {
      this.isOff = this.isOff2
      this.showOff = false
    },
    reqData() {
      put('/api/PostAuthFunc?IsShow=' + (this.isOff ? 1 : 0))
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '设置成功',
            })
            if (
              this.$store.state.menuList[2].son[4].title != '岗位职能表' &&
              this.isOff
            ) {
              this.$store.state.menuList[2].son.splice(4, 0, {
                path: '/post-fun-table',
                title: '岗位职能表',
              })
            }
            if (
              this.$store.state.menuList[2].son[4].title == '岗位职能表' &&
              !this.isOff
            ) {
              this.$store.state.menuList[2].son.splice(4, 1)
            }
            this.isOff2 = this.isOff
            this.showOff = false
            this.$store.dispatch('postAudFunStatus', this.isOff)
            this.$store.dispatch('menuList', this.$store.state.menuList)
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('系统异常，请稍后再试')
        })
    },
  },
}
</script>

<style lang="less" scoped>
.set-box {
  width: 400px;
  height: 200px;
  box-shadow: 0px 1px 5px silver;
  margin-top: 110px;
  margin-left: 200px;
  padding-top: 50px;
  padding-left: 50px;
  position: relative;

  p {
    font-size: 16px;
    span {
      font-size: 20px;
      color: blue;
      font-weight: bold;
    }
  }
  .set-btn {
    font-size: 16px;
    margin-top: 25px;
  }
  /deep/ .el-button {
    transform: scale(1.2);
  }
}
</style>